import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Em } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				La Dolce Pizza
			</title>
			<meta name={"description"} content={"Ihr perfektes Stück erwartet Sie"} />
			<meta property={"og:title"} content={"La Dolce Pizza"} />
			<meta property={"og:description"} content={"Ihr perfektes Stück erwartet Sie"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10:42:28.096Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10:42:28.096Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10:42:28.096Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10:42:28.096Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10:42:28.096Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10:42:28.096Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10:42:28.096Z"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header12>
		<Section
			padding="140px 0 140px 0"
			sm-padding="40px 0 40px 0"
			background="linear-gradient(120deg,rgba(0,0,0,.2) 0%,rgba(16, 1, 1, 0.73) 100%),--color-dark url(https://uploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/1-2.jpg?v=2024-07-11T10:42:28.069Z) bottom/cover"
			color="--light"
			font="--base"
		>
			<Override slot="SectionContent" sm-align-items="center" />
			<Box width="100%">
				<Text
					as="h1"
					margin="0 0 96px 0"
					text-align="center"
					letter-spacing="20px"
					text-transform="uppercase"
					font="--headline3"
					lg-font="200 42px/1.2 --fontFamily-sans"
					lg-letter-spacing="20px"
				>
					Entdecken Sie unsere kulinarischen Köstlichkeiten
				</Text>
			</Box>
			<Box
				display="flex"
				margin="-16px -16px -16px -16px"
				flex-wrap="wrap"
				width="100%"
				justify-content="space-between"
			>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="50%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px" font="--base" text-transform="uppercase">
							Unsere Speisekarte ist eine Hommage an die kulinarische Tradition Italiens und bietet eine Vielzahl von Gerichten, die mit Leidenschaft und Sorgfalt zubereitet werden.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex" />
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://uploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10:42:28.096Z"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
						srcSet="https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10%3A42%3A28.096Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10%3A42%3A28.096Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10%3A42%3A28.096Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10%3A42%3A28.096Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10%3A42%3A28.096Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10%3A42%3A28.096Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10%3A42%3A28.096Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				align-self="center"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Willkommen auf unserer Speisekarte
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Bei La Dolce Pizza bieten wir mehr als nur Pizza. Unsere Speisekarte ist eine Reise durch die reiche kulinarische Landschaft Italiens und präsentiert eine Vielzahl von Gerichten, die die Aromen und Traditionen der italienischen Küche hervorheben. Jedes Gericht auf unserer Speisekarte wird mit den frischesten Zutaten und größter Liebe zum Detail zubereitet, um ein kulinarisches Erlebnis zu gewährleisten, das sowohl köstlich als auch unvergesslich ist.
				</Text>
			</Box>
		</Section>
		<Section
			padding="60px 0 60px 0"
			md-padding="80px 0 80px 0"
			background="--color-darkL1"
			color="--dark"
			quarkly-title="Reviews-14"
		>
			<Override slot="SectionContent" md-padding="0px 0 0px 0" grid-template-columns="repeat(3, 1fr)" grid-template-rows="auto" />
			<Text margin="0px 0px 15px 0px" color="--light" font="--headline2" text-align="center">
				Unsere charakteristischen Kreationen
			</Text>
			<Text margin="0px 0px 50px 0px" color="#b2bcc3" font="--lead" text-align="center">
				Unsere Pizzen sind das Herz und die Seele von La Dolce Pizza. Jede wird mit einer einzigartigen Mischung aus frischen Zutaten und traditionellen Rezepten hergestellt und bietet einen authentischen Geschmack, der seinesgleichen sucht.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				border-radius="8px"
				margin="0px 20px 25px 20px"
				display="grid"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 25px 0px"
				md-flex-direction="column"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					border-color="#202757"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-border-width="0 0 1px 0"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 10px 0px">
						<Text margin="0px 0px 1rem 0px" color="--light" font="--headline3">
							Klassische Pizzas
						</Text>
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							Margherita: Frische Tomaten, Mozzarella und Basilikum.
							<br />
							{"\n"}Peperoni: Scharfe Peperoni mit Mozzarella und Tomatensauce.
							<br />
							Vegetarisch: Eine Mischung aus frischem Gemüse mit Mozzarella und Tomatensauce.
							<br />
							Spezialitätspizzas
La Dolce Special: Prosciutto, Rucola und gehobelter Parmesan.
							<br />
							{"\n"}Meeresfrüchte-Genuss: Garnelen, Calamari und frische Kräuter.
							<br />
							Vier Käsesorten: Eine Mischung aus Mozzarella, Parmesan, Gorgonzola und Ziegenkäse.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
				>
					<Text margin="0px 0px 1rem 0px" color="--light" font="--headline3">
						Ein Hauch von Tradition
					</Text>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							Unsere Pastagerichte sind eine Hommage an die reiche Tradition der italienischen Küche.{" "}
							<br />
							Von klassischen Rezepten bis hin zu innovativen Kreationen ist jedes Gericht ein Meisterwerk.{"\n"}
							<br />
							Klassische Pastas
Spaghetti Carbonara: Pancetta, Eier und Parmesan.{"\n"}
							<br />
							Penne Arrabbiata: Scharfe Tomatensauce mit Knoblauch und Chili.{"\n"}
							<br />
							Lasagne: Schichten aus Pasta, Fleischsauce und cremiger Béchamelsauce.
Spezialität der Pasta
Pesto Genovese: Frisches Basilikumpesto mit Pinienkernen und Parmesan.{"\n"}
							<br />
							Meeresfrüchte-Linguine: Muscheln, Venusmuscheln und Garnelen in einer Weißweinsauce.
Pilzrisotto: Arborio-Reis mit einer Mischung aus Wildpilzen.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				border-radius="8px"
				margin="0px 20px 25px 20px"
				display="grid"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 25px 0px"
				md-flex-direction="column"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					border-color="#202757"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-border-width="0 0 1px 0"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 10px 0px">
						<Text margin="0px 0px 1rem 0px" color="--light" font="--headline3">
							Ein süßes Finale
						</Text>
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							Keine Mahlzeit bei La Dolce Pizza ist vollständig, ohne dass Sie sich eines unserer köstlichen Desserts gegönnt haben.{" "}
							<br />
							Jede süße Köstlichkeit ist der perfekte Abschluss Ihres kulinarischen Erlebnisses.{"\n"}
							<br />
							Traditionelle Desserts
Tiramisu: Schichten aus in Kaffee getränkten Löffelbiskuits und Mascarponecreme.{"\n"}
							<br />
							Cannoli: Knusprige Teigschalen, gefüllt mit süßem Ricotta und Schokoladenstückchen.{"\n"}
							<br />
							Panna Cotta: Seidig-weicher Vanillepudding, garniert mit frischen Beeren.
Spezialdesserts
Affogato: Vanille-Gelato, „ertränkt“ in einem Schuss heißen Espressos.{"\n"}
							<br />
							Schokoladen-Lavakuchen: Warmer Schokoladenkuchen mit flüssiger Mitte.
Gelato Trio: Drei Kugeln handgemachtes italienisches Eis.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 1rem 0px" color="--light" font="--headline3">
							Der perfekte Start
						</Text>
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							Beginnen Sie Ihre Mahlzeit mit unseren köstlichen Vorspeisen, die Ihren Gaumen verwöhnen und die Bühne für ein unvergessliches kulinarisches Erlebnis bereiten.
Antipasti
Bruschetta: Frische Tomaten, Basilikum und Knoblauch auf geröstetem Brot.
Caprese-Salat: Mozzarella-, Tomaten- und Basilikumscheiben mit Balsamico-Glasur beträufelt.
Knoblauchbrot: Warmes Brot mit Knoblauchbutter und Kräutern belegt.
Kleine Teller
Calamari: Leicht paniert und frittiert, serviert mit Marinarasoße.
Gefüllte Champignons: Mit einer würzigen Käse- und Kräutermischung gefüllte Champignons.
Arancini: Knusprige Reisbällchen, gefüllt mit Mozzarella und serviert mit Marinarasoße.
						</Text>
					</Box>
				</Box>
			</Box>
			<Text margin="0px 0px 50px 0px" color="#b2bcc3" font="--base" text-align="center">
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Bei La Dolce Pizza bieten wir eine Reihe von Dienstleistungen, um Ihr kulinarisches Erlebnis zu verbessern. Von der Ausrichtung privater Veranstaltungen bis hin zur Bereitstellung von Catering für Ihre besonderen Anlässe sind wir hier, um Ihre Feier unvergesslich zu machen.
Private Veranstaltungen: Unser Restaurant ist der perfekte Veranstaltungsort für Ihre nächste Zusammenkunft. Wir bieten anpassbare Menüs und engagierten Service, um sicherzustellen, dass Ihre Veranstaltung ein Erfolg wird.
Catering-Services: Lassen Sie uns die Aromen von La Dolce Pizza zu Ihrer Veranstaltung bringen. Unser Catering-Team arbeitet mit Ihnen zusammen, um ein Menü zu erstellen, das Ihren Bedürfnissen entspricht und Ihre Gäste begeistert.
Besondere Anlässe: Feiern Sie Geburtstage, Jubiläen und andere Meilensteine ​​mit uns. Wir bieten spezielle Pakete und persönlichen Service, um Ihren Anlass unvergesslich zu machen.
				</Em>
			</Text>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://uploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-2.jpg?v=2024-07-11T10:42:28.094Z"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
						srcSet="https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-2.jpg?v=2024-07-11T10%3A42%3A28.094Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-2.jpg?v=2024-07-11T10%3A42%3A28.094Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-2.jpg?v=2024-07-11T10%3A42%3A28.094Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-2.jpg?v=2024-07-11T10%3A42%3A28.094Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-2.jpg?v=2024-07-11T10%3A42%3A28.094Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-2.jpg?v=2024-07-11T10%3A42%3A28.094Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-2.jpg?v=2024-07-11T10%3A42%3A28.094Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				align-self="center"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Erleben Sie das Beste der italienischen Küche
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Bei La Dolce Pizza sind wir leidenschaftlich daran interessiert, unvergessliche kulinarische Erlebnisse zu schaffen. Unsere mit Sorgfalt und Hingabe zusammengestellte Speisekarte soll Ihnen den wahren Geschmack Italiens näherbringen. Egal, ob Sie eine klassische Margherita oder eine unserer einzigartigen Spezialpizzas genießen, wir garantieren, dass jeder Bissen ein Moment purer Freude sein wird.
				</Text>
			</Box>
		</Section>
		<Components.Footer23 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});